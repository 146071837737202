enum EModalOp {
  create,
  edit
}

enum EHostType {
  PVE = "pve",
  CEPH = "ceph"
}

enum EChannelType {
  // 用户信息操作更新状态机
  TRIGGER_USER = "msgChannel",
  // 登出时清理状态
  CLEAN_USER = "cleanUserChannel",
  // 周期刷新用户信息时广播
  CHANGE_USER = "CHANGE_USER",
  // 周期刷新二维码事件
  TRIGGER_QR = "msgQrChannel",


}

export { EModalOp, EHostType, EChannelType }