
import { EChannelType } from "@/core/utils/enums/CommEnum";

class CommFun {

  public static sendUserInfoEvent(source: string) {
    if (source) {
      new Broadcast(EChannelType.CHANGE_USER).setTrigger(source)
    }

    // const channel = new BroadcastChannel("msgChannel");
    // channel.postMessage({ type: "msg", source: source })
  }

  public static getUserInfoEvent(fn: Function) {

    console.log("getuserInfo Event...")
    new Broadcast(EChannelType.CHANGE_USER).getTrigger(fn)

    // const channel = new BroadcastChannel("msgChannel");
    // channel.postMessage({ type: "msg", source: source })
  }


  public static sendCleanUserEvent(source: string) {
    new Broadcast(EChannelType.CLEAN_USER).setTrigger(source)
  }

  public static getCleanUserEvent(fn: Function) {
    new Broadcast(EChannelType.CLEAN_USER).getTrigger(fn)
  }

  // 周期刷新用户信息时触发
  public static sendTiggerUserEvent(source: string) {
    new Broadcast(EChannelType.TRIGGER_USER).setTrigger(source)
  }

  public static getTiggerUser(fn: Function) {
    return new Broadcast(EChannelType.TRIGGER_USER).getTrigger(fn)
  }

  // 周期检测二维码事件
  public static sendTiggerQRCodeEvent(source: string) {
    new Broadcast(EChannelType.TRIGGER_QR).setTrigger(source)
  }

  public static getTiggerQRCodeEvent(fn: Function) {
    return new Broadcast(EChannelType.TRIGGER_QR).getTrigger(fn)
  }



  public static getTotalGPUCores(computeCore: number, cpuExtra: string): number {
    const cpuCoreObj = JSON.parse(cpuExtra)
    // 最大值向下取整，并向下取偶数
    let maxCpu = Number.parseInt(`${cpuCoreObj.total_core * 0.8}`)
    if (maxCpu % 2 != 0)
      maxCpu--

    return Math.min(computeCore, maxCpu)
  }

  public static getTotalMemory(computeMemory: number, totalCount: number): number {
    return computeMemory * totalCount
  }

  public static ISODataToDataFormat(isoData: string): string {
    let date = new Date(isoData)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()


  }

  public static hideModal = (idElement: string) => {
    document.getElementById(idElement)?.click()
  }

  public static getUserObj = () => {
    const userStorage = localStorage.getItem("userInfo")
    return userStorage ? JSON.parse(userStorage) : {}
  }

  public static getTmpUser = () => {
    const userStorage = localStorage.getItem("_tmpUser")

    return userStorage ? JSON.parse(userStorage) : {}
  }
}

class Broadcast {
  public channel: any;
  constructor(channelName: string) {
    this.channel = new BroadcastChannel(channelName);
  }
  setTrigger(source) {
    this.channel.postMessage({ type: "msg", source: source })
  }

  getTrigger(callBack: Function) {
    this.channel.onmessage = event => {
      callBack(event.data)
    }
  }

  getCallMsg(): object {
    let callObj = {}
    this.channel.onmessage = event => {

      console.log("---=-----------")
      console.log(event.data)


      this.channel.close()
    }

    return callObj
  }

}

export { CommFun, Broadcast }