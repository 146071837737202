
import { ref, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { UserOrderActions, UserOrderMutations } from "@/store/enums/UserOrderEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResponseErrorFun, encodeUrlParam } from "@/core/utils/Tools";
import { throwError } from "element-plus/es/utils";

export interface UserOrderInfo {
	errors: unknown;
	bill_list: [Bill];
	subscribe_list: [Subscribe]
	currentSub: {}
	currentSubAmount: number
	subscribe_detail: {}
	purchase_list: [Purchase]
	user_inst_list: [any]
}

export interface Subscribe {
	id: number;
	created_at: string;
	updated_at: string;
	create_ip: string;
	update_ip: string;
	create_by: number;
	update_by: number;
	uuid: string;
	user_id: number;
	eta_expire_at: string;
	initiate_at: string;
	charge_cycle: CHARGE_CYCLE;
	gpu_count: number;
	gpu_price: number;
}

enum CHARGE_CYCLE {
	HOUR = 1,
	DAY = 2,
	WEEK = 3,
	MONTH = 4
}
export interface Purchase {
	id: number;
	created_at: string;
	updated_at: string;
	create_ip: string;
	update_ip: string;
	create_by: number;
	update_by: number;
	type: BillType;
	type_desc: string;
	user_id: number;
	wire_amount: number;
	wire_type: PayType;
	wire_status: number;
	wire_time: string;
	remark: string;
}

enum PayType {
	ALI_PAY = 1,
	WECHAT_PAY = 2,
	UNION_PAY = 3
}

export interface Bill {
	id: number;
	created_at: string;
	updated_at: string;
	create_ip: string;
	update_ip: string;
	create_by: number;
	update_by: number;
	uuid: string;
	user_id: number;
	user: null,
	remark: string;
	transition_id: number;
	type: BillType;
	type_desc: string;
	amount: number;
	inst_info: any;
}

enum BillType {
	PURCHASE = 1,
	REFUND = 2,
	EXPEND = 3,
	BONUS = 4,
}

@Module
export default class UserOrderModule extends VuexModule implements UserOrderInfo {
	errors = {};
	respObj = ref({})
	bill_list = Array<Bill>() as [Bill];
	bill_total = 0

	bonus_list = Array<Bill>() as [Bill];
	bonus_total = 0

	purchase_list = Array<Purchase>() as [Purchase];
	purchase_total = 0

	refund_list = Array<Purchase>() as [Purchase];
	refund_total = 0

	subscribe_list = Array<Subscribe>() as [Subscribe];
	subscribe_total = 0
	subscribe_detail = {}
	currentSub = {}
	currentSubAmount = 0
	user_inst_list = Array<Object>() as [Object];
	user_inst_total = 0

	pay_activity_list: Object[] = Array<Object>()

	vnc_pwd = ""
	os_pwd = ""

	get getUserOrderError(): any {
		return this.errors
	}

	@Mutation
	[UserOrderMutations.SET_ORDER_ERROR](err: any) {
		this.errors = err
	}

	get getCurrentSub(): any {
		return this.currentSub
	}

	@Mutation
	[UserOrderMutations.SET_CURRENT_SUBSCRIBE](obj: any) {
		this.currentSub = obj
	}

	get getBillList(): [Bill] {
		return this.bill_list;
	}

	get getBillTotal(): number {
		return this.bill_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_BILL_LIST](resp: any) {

		this.bill_list = resp.data;
		this.bill_total = resp.total;

		// var dict = {}
		// for (var i = 0; i < resp.data.length; i++) {
		// 	let bill = resp.data[i] as Bill
		// 	let sub_id = bill.transition_id as number
		// 	dict[sub_id] = {}
		// }

		// let keys = Object.keys(dict) as string[]
		// for (var j = 0; j < keys.length; j++) {
		// 	let key = keys[j]
		// 	ApiService.get(`/api/subscribe/get?id=${key}`)
		// 		.then(({data}) => {
		// 			if (data.code == 0) {
		// 				dict[key] = data.content
		// 				for (var k = 0; k < this.bill_list.length; k++) {
		// 					let bill = this.bill_list[k]
		// 					bill.inst_info = data.content
		// 				}

		// 				debugger
		// 			}

		// 		})
		// }


	}

	@Action
	[UserOrderActions.GET_BILL_LIST](payload: any) {

		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/bill/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_BILL_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getBonusList(): [Bill] {
		return this.bonus_list;
	}

	get getBonusTotal(): number {
		return this.bonus_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_BONUS_LIST](data: any) {

		this.bonus_list = data.data;
		this.bonus_total = data.total;
	}

	@Action
	[UserOrderActions.GET_BONUS_LIST](payload: any) {

		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/bill/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_BONUS_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getSubscribeList(): [Subscribe] {
		return this.subscribe_list;
	}

	get getSubscribeTotal(): number {
		return this.subscribe_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_SUBSCRIBE_LIST](data: any) {
		this.subscribe_list = data.data;
		this.subscribe_total = data.total;
	}

	@Action
	[UserOrderActions.GET_SUBSCRIBE_LIST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/subscribe/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_SUBSCRIBE_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getPurchaseList(): [Purchase] {
		return this.purchase_list;
	}

	get getPurchaseTotal(): number {
		return this.purchase_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_PURCHASE_LIST](data: any) {
		this.purchase_list = data.data;
		this.purchase_total = data.total;
	}

	@Action
	[UserOrderActions.GET_PURCHASE_LIST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/fulfill/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_PURCHASE_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getRefundList(): [Purchase] {
		return this.refund_list;
	}

	get getRefundTotal(): number {
		return this.refund_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_REFUND_LIST](data: any) {
		this.refund_list = data.data;
		this.refund_total = data.total;
	}

	@Action
	[UserOrderActions.GET_REFUND_LIST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/fulfill/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_REFUND_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getSubscribeDetail(): any {
		return this.subscribe_detail;
	}

	@Mutation
	[UserOrderMutations.UPDATE_SUBSCRIBE_DETAIL](data: any) {
		this.subscribe_detail = data;
	}

	@Action
	[UserOrderActions.GET_SUBSCRIBE_DETAIL](oid: number) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.get(`/api/subscribe/get?id=${oid}`)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_SUBSCRIBE_DETAIL, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getCurrentSubAmount(): number {
		return this.currentSubAmount;
	}

	@Mutation
	[UserOrderMutations.UPDATE_SUBSCRIBE_AMOUNT](data: number) {
		this.currentSubAmount = data;
	}

	@Action
	[UserOrderActions.GET_SUBSCRIBE_AMOUNT](id: number) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");

		return ApiService.get(`/api/subscribe/amount?id=${id}`)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_SUBSCRIBE_AMOUNT, data.content.amount);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.SUBMIT_SUBSCRIBE](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/subscribe/buy", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, [data.detail]);
				} else {

				}
			})
			.catch(({ response }) => {

				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.CLOSE_SUBSCRIBE](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/subscribe/close", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, [data.detail]);
				} else {
					this.respObj = data.content
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getUserInstList(): [Object] {
		return this.user_inst_list;
	}

	get getUserInstTotal(): number {
		return this.user_inst_total;
	}

	@Mutation
	[UserOrderMutations.UPDATE_USER_INST_LIST](data: any) {
		this.user_inst_list = data.data;
		this.user_inst_total = data.total;
	}

	@Action
	[UserOrderActions.GET_USER_INST_LIST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_USER_INST_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.START_USER_INST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/start", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {

					// this.user_inst_list.find(o => o.id == payload.id)
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.SET_INST_ALAISNAME](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/update/alias", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}



	@Action
	[UserOrderActions.STOP_USER_INST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/stop", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_USER_INST_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.RESET_INST_PWD](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/set_pwd", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_USER_INST_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.CHANGE_INST_CYCLE](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/subscribe/update_cycle", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}



	@Action
	[UserOrderActions.FORCE_RESTART_INST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/restart", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {

				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserOrderActions.FORCE_CLOSE_INST](payload: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.post("/api/device/inst/close", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {

				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}


	get getPayActivityList(): Object[] {
		return this.pay_activity_list;
	}

	@Mutation
	[UserOrderMutations.UPDATE_PAY_ACTIVITY_LIST](data: any) {
		this.pay_activity_list = data
	}

	@Action
	[UserOrderActions.GET_PAY_ACTIVITY_LIST]() {

		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.get("/api/dict_bonus/get")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_PAY_ACTIVITY_LIST, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getOSPwd(): string {
		return this.os_pwd;
	}

	@Mutation
	[UserOrderMutations.UPDATE_OS_PWD](data: any) {
		this.os_pwd = data;
	}

	@Action
	[UserOrderActions.GET_OS_PWD](id: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.get(`/api/device/inst/os_pwd?id=${id}`)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_OS_PWD, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}

	get getVNCPwd(): string {
		return this.vnc_pwd;
	}

	@Mutation
	[UserOrderMutations.UPDATE_VNC_PWD](data: any) {
		this.vnc_pwd = data;
	}

	@Action
	[UserOrderActions.GET_VNC_PWD](id: any) {
		this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "");
		return ApiService.get(`/api/device/inst/vnc_pwd?id=${id}`)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, data.detail);
				} else {
					this.context.commit(UserOrderMutations.UPDATE_VNC_PWD, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, "error") :
					this.context.commit(UserOrderMutations.SET_ORDER_ERROR, response.data.detail);
			})
	}
}


