
	import { defineComponent, nextTick, onMounted, ref, provide } from "vue";
	import { useStore } from "vuex";
	import { Mutations, Actions } from "@/store/enums/StoreEnums";
	import { UserActions, UserMutations } from "@/store/enums/StoreUserEnums";
	import { themeMode } from "@/core/helpers/config";
	import { initializeComponents } from "@/core/plugins/keenthemes";

	export default defineComponent({
		name: "app",
		setup() {
			const store = useStore();
			const isRouter = ref(true);

			// 提供一个重载页面的方法
			const reload = (flag) => {
				isRouter.value = false;
				nextTick(() => {
					isRouter.value = true;
				});
			}

			provide("reload", reload);

			onMounted(() => {
				/**
				 * Overrides the layout config using saved data from localStorage
				 * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
				 */
				store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

				if (store.getters.isUserAuthenticated == true) {
					try {
						store.dispatch(UserActions.GET_USER_INST_CNT);
						let userInfo = localStorage.getItem("userInfo") as string
						let jUser = JSON.parse(userInfo)
						if (jUser != null && jUser.token != null) {
							store.commit(Mutations.SET_USER, jUser)
						}
					} catch (e) {

					}
				}

				/**
				 *  Sets a mode from configuration
				 */
				store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

				nextTick(() => {
					initializeComponents();

					store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
				});
			});
			return {
				isRouter
			}
		},

	});
