enum UserOrderActions {
	// action types
	SUBMIT_SUBSCRIBE = "SUBMIT_SUBSCRIBE",
	CLOSE_SUBSCRIBE = "CLOSE_SUBSCRIBE",
	GET_SUBSCRIBE_LIST = "GET_SUBSCRIBE_LIST",
	GET_SUBSCRIBE_DETAIL = "GET_SUBSCRIBE_DETAIL",
	GET_SUBSCRIBE_AMOUNT = "GET_SUBSCRIBE_AMOUNT",
	GET_PURCHASE_LIST = "GET_PURCHASE_LIST",
	GET_REFUND_LIST = "GET_REFUND_LIST",
	GET_BILL_LIST = "GET_BILL_LIST",
	GET_BONUS_LIST = "GET_BONUS_LIST",
	GET_BILL_TYPE_DESC = "GET_BILL_TYPE_DESC",
	GET_PAY_TYPE_DESC = "GET_PAY_TYPE_DESC",
	GET_CHARGE_CYCLE_DESC = "GET_CHARGE_CYCLE_DESC",
	GET_USER_INST_LIST = "GET_USER_INST_LIST",
	SET_INST_ALAISNAME = "SET_INST_ALAISNAME",
	START_USER_INST = "START_USER_INST",
	STOP_USER_INST = "STOP_USER_INST",
	RESET_INST_PWD = "RESET_INST_PWD",
	CHANGE_INST_CYCLE = "CHANGE_INST_CYCLE",

	FORCE_RESTART_INST = "FORCE_RESTART_INST",
	FORCE_CLOSE_INST = "FORCE_CLOSE_INST",

	GET_PAY_ACTIVITY_LIST = "GET_PAY_ACTIVITY_LIST",
	GET_VNC_PWD = "GET_VNC_PWD",
	GET_OS_PWD = "GET_OS_PWD",
}

enum UserOrderMutations {
	// mutation types
	SET_ORDER_ERROR = "SET_ORDER_ERROR",
	SET_CURRENT_SUBSCRIBE = "SET_CURRENT_SUBSCRIBE",
	UPDATE_SUBSCRIBE_LIST = "UPDATE_SUBSCRIBE_LIST",
	UPDATE_SUBSCRIBE_DETAIL = "UPDATE_SUBSCRIBE_DETAIL",
	UPDATE_SUBSCRIBE_AMOUNT = "UPDATE_SUBSCRIBE_AMOUNT",
	UPDATE_PURCHASE_LIST = "UPDATE_PURCHASE_LIST",
	UPDATE_REFUND_LIST = "UPDATE_REFUND_LIST",
	UPDATE_BILL_LIST = "UPDATE_BILL_LIST",
	UPDATE_BONUS_LIST = "UPDATE_BONUS_LIST",
	UPDATE_USER_INST_LIST = "UPDATE_USER_INST_LIST",

	UPDATE_PAY_ACTIVITY_LIST = "UPDATE_PAY_ACTIVITY_LIST",
	UPDATE_VNC_PWD = "UPDATE_VNC_PWD",
	UPDATE_OS_PWD = "UPDATE_OS_PWD",
}

export { UserOrderActions, UserOrderMutations };
