import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import axios from "axios";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { CommFun } from "@/core/utils/tools/CommFun";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessage } from 'element-plus'
import "@/core/plugins/prismjs";

enum RESP_ERROR_CODE {
    PERMISSION_DENIED = 409010,
    SESSION_TIMEOUT = 400020
}

// app.use(LOVEGPU_CONF)

// 添加拦截器， 将Token登录失败等直接返回登录页面
axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        console.log("拦截器拦截器")
        console.log(err.response)
        console.log(err.response.data.code)
        switch (err.response.data.code) {

            case RESP_ERROR_CODE.PERMISSION_DENIED:
                ElMessage({
                    showClose: true,
                    message: err.response.data.detail,
                    type: 'error',
                })


                return Promise.reject(err)
                break

            case RESP_ERROR_CODE.SESSION_TIMEOUT:
                console.error("登录失效，请重新登录..")
                // 广播登出事件 

                store
                    .dispatch(Actions.LOGOUT)
                    .then(() => { router.push({ name: "sign-in" }) })

                CommFun.sendCleanUserEvent("sinout")
                break

            default:

                console.log(err.response)

                // Swal.fire({
                //     text: err.response.data.Detail,
                //     icon: "error",
                //     buttonsStyling: false,
                //     confirmButtonText: "确定!",
                //     customClass: {
                //     confirmButton: "btn btn-primary",
                //     },
                // }).then(() => {})

                // return err
                return Promise.reject(err)
        }
    }
)


const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);


ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

// 加载正式环境配置
axios.get('/config.json')
    .then(res => {
        localStorage.setItem("NDP_CONFIG", JSON.stringify(res.data))
        ApiService.init(app);
    })

app.mount("#app");


const LOVEGPU_CONF: Object = {
    version: "V1.1.20240105",
    domain: {
        docs: {
            home: "https://docs.lovegpu.com/",
            privacy: "https://docs.lovegpu.com/docs/服务协议/用户服务协议/",
            help: "https://docs.lovegpu.com/docs/使用手册/注册和登录",
            ai: {
                sd: "https://docs.lovegpu.com/docs/AIGC/SD/",
            }
        },
        cloud: {
            root: "https://lovegpu-software-1318100588.cos.ap-shanghai.myqcloud.com",
            images: "https://lovegpu-software-1318100588.cos.ap-shanghai.myqcloud.com/images"
        }
    }
}

app.config.globalProperties.$LOVEGPU_CONF = LOVEGPU_CONF
