
import { ref, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { MarketActions, MarketMutations } from "@/store/enums/MarketEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResponseErrorFun, encodeUrlParam } from "@/core/utils/Tools";


export interface MarketInfo {
  errors: unknown;
  publics: [GPUItem];
  area_group: [Area];
  gpu_spec_group: [GPUSpec];
  
}

export interface Area {
	id: number;
	name: string;
}

export interface GPUItem {
	total: number;
	name: string;
	hourly_price: number;
	daily_price: number;
	weekly_price: number;
	monthly_price: number;
	cpu_core: number;
	cpu_name: string;
	cpu_extra: string;
	disk_size: number;
	host_memory_size: number;
	spec_id: number;
	label: string;
	host_name: string;
	area_id: number;
	area: AreaInfo;
	gpu_spec: GPUSpec;
	template0: TemplateInst;
	template_insts: TemplateInst[];
}

export interface AreaInfo {
	id: number;
	name: string;
}

export interface TemplateInst {
	id: number;
	remark: string;
	host_id: number;
	host: string;
	template_spec_id: number;
	template_spec: TemplateSpec
}

export interface TemplateSpec {
	id: number;
	os_type: string;
	alloc_cpu_core: number;
	alloc_disk: number;
	alloc_memory: number;
	extra: string;
	
}

export interface GPUSpec {
	id: number;
	name: string;
	type: string;
	memory_size: number;
	bus_width: number;
	cores: number;
	company: number;
	cuda: string;
	fp_half: number;
	fp_float: number;
	hotness: number;
}

@Module
export default class MarketModule extends VuexModule implements MarketInfo {
	errors = {};
	publics = Array<GPUItem>() as [GPUItem];
	area_group = Array<Area>() as [Area];
	gpu_spec_group = Array<GPUSpec>() as [GPUSpec];


	notices = Array<Object>() as [{}]
	
	respObj = ref({})
	
	get getPublics(): [GPUItem] {
		return this.publics;
	}

	get getNotices() {
		return this.notices
	}

	get getMError() {
		return this.errors
	}
	
	@Mutation
	[MarketMutations.SET_MARKET_ERROR](data: string) {
	    this.errors = data;
	}
	
	@Mutation
	[MarketMutations.UPDATE_PUBLIC_PAGES](data: any) {
		this.publics = Array<GPUItem>() as [GPUItem];
		let temp = Array<GPUItem>() as [GPUItem];

		for (var i = 0; i < data.data.length; i++) {
			let host = data.data[i];
			
			if (host.template_insts == null || host.template_insts.length == 0) {
				continue;
			}
			
			let cpu_extra_str = host.cpu_extra;
			let cpu_extra_obj = JSON.parse(cpu_extra_str);
			let disk_extra_str = host.disk_extra;
			let disk_extra_obj = JSON.parse(disk_extra_str);
			let mem_extra_str = host.memory_extra;
			let mem_extra_obj = JSON.parse(mem_extra_str);
			let item = reactive<GPUItem>({
				total: host.gpus.length,
				name: host.gpu_info.gpu_spec.type,
				hourly_price: host.gpu_info.hourly_price,
				daily_price: host.gpu_info.daily_price,
				weekly_price: host.gpu_info.weekly_price,
				monthly_price: host.gpu_info.monthly_price,
				cpu_core: cpu_extra_obj.total_core,
				cpu_name: cpu_extra_obj.name,
				disk_size: disk_extra_obj.total_size,
				host_memory_size: mem_extra_obj.total_count,
				spec_id: host.gpu_info.gpu_spec_id,
				gpu_spec: host.gpu_info.gpu_spec,
				template_insts: host.template_insts,
				label: host.label,
				host_name: host.host_name,
				area_id: host.area_id,
				area: host.area,
				template0: host.template_insts[0],
				cpu_extra: host.cpu_extra
			})
			
			temp.push(item)
		}
		
		temp.forEach(o => o.gpu_spec.hotness = o.gpu_spec.hotness == 0 ? 9999 : o.gpu_spec.hotness)
		
		let level1 = temp.filter(o => o.total > 0).sort((a, b) => { 
			if (a.gpu_spec.hotness == b.gpu_spec.hotness) {
				return b.total - a.total
			}
			return a.gpu_spec.hotness - b.gpu_spec.hotness 
		})
		let level2 = temp.filter(o => o.total == 0).sort((a, b) => { 
			return a.gpu_spec.hotness - b.gpu_spec.hotness 
		})
		
		level1.forEach(o => this.publics.push(o))
		level2.forEach(o => this.publics.push(o))

	}

	@Mutation
	[MarketMutations.SET_NOTICE](data: any) {
	    this.notices = data;
	}
	
	@Action
	[MarketActions.GET_PUBLIC_PAGES](payload: any) {
		this.context.commit(MarketMutations.SET_MARKET_ERROR, "");
		return ApiService.post("/api/device/host/all", payload)
			.then(({data}) => {
				if (data.code != 0) {
					this.context.commit(MarketMutations.SET_MARKET_ERROR, data.detail);
				} else {
					this.context.commit(MarketMutations.UPDATE_PUBLIC_PAGES, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ? 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, "error"): 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, response.data.detail);
			})
	}
	
	get getAreas(): [Area] {
		return this.area_group;
	}
	
	@Mutation
	[MarketMutations.UPDATE_AREA_GROUP](data: any) {
		this.area_group = data;
	}
	
	@Action
	[MarketActions.GET_AREA_GROUP]() {
		this.context.commit(MarketMutations.SET_MARKET_ERROR, "");
		return ApiService.get("/api/device/area/all")
			.then(({data}) => {
				if (data.code != 0) {
					this.context.commit(MarketMutations.SET_MARKET_ERROR, data.detail);
				} else {
					this.context.commit(MarketMutations.UPDATE_AREA_GROUP, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ? 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, "error"): 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, response.data.detail);
			})
	}

	get getGPUSpecs(): [GPUSpec] {
		return this.gpu_spec_group;
	}
	
	@Mutation
	[MarketMutations.UPDATE_GPU_SPEC_GROUP](data: any) {
		this.gpu_spec_group = data;
	}
	
	@Action
	[MarketActions.GET_GPU_SPEC_GROUP](area_id: any) {
		this.context.commit(MarketMutations.SET_MARKET_ERROR, "");		
		return ApiService.get(encodeUrlParam("/api/device/gpu_spec/all", area_id))
			.then(({data}) => {

				if (data.code != 0) {
					this.context.commit(MarketMutations.SET_MARKET_ERROR, data.detail);
				} else {
					this.context.commit(MarketMutations.UPDATE_GPU_SPEC_GROUP, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ? 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, "error"): 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, response.data.detail);
			})
	}

	@Action
	[MarketActions.PAGE_NOTICE](payload: Object) {
		this.context.commit(MarketMutations.SET_MARKET_ERROR, "");		
		return ApiService.post("/api/notice/carousel/page", payload)
			.then(({data}) => {

				if (data.code != 0) {
					this.context.commit(MarketMutations.SET_MARKET_ERROR, data.detail);
				} else {
					this.context.commit(MarketMutations.SET_NOTICE, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ? 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, "error"): 
					this.context.commit(MarketMutations.SET_MARKET_ERROR, response.data.detail);
			})
	}

	@Action
	[MarketActions.GET_NOTICE](kwargs: any) {
	this.context.commit(MarketMutations.SET_MARKET_ERROR, "");		
	// return ApiService.post("/api/notice/carousel/page", payload)
	return ApiService.get(encodeUrlParam("/api/notice/carousel/get", kwargs))
		.then(({data}) => {

			if (data.code != 0) {
				this.context.commit(MarketMutations.SET_MARKET_ERROR, data.detail);
			} else {
				this.context.commit(MarketMutations.SET_NOTICE, data.content);
			}
		})
		.catch(({ response }) => {
			response === undefined ? 
				this.context.commit(MarketMutations.SET_MARKET_ERROR, "error"): 
				this.context.commit(MarketMutations.SET_MARKET_ERROR, response.data.detail);
		})
}
}


