
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { UserActions, UserMutations } from "@/store/enums/StoreUserEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ResponseErrorFun, encodeUrlParam } from "@/core/utils/Tools";
import { dataType } from "element-plus/es/components/table-v2/src/common";
import { ISubUser, IRespPageSubUser } from "@/core/utils/interfaces/UserInterface";



export interface User {
	name: string;
	surname: string;
	email: string;
	password: string;
	api_token: string;

	nick_name: string;
	phone: string;
	wx_bind: boolean;
	allow_sub: number;
	balance: number;
	token: string;
}

// export interface User {
//   nick_name: string;
//   phone: string;
//   wx_bind: boolean;
//   balance: number;
//   token: string;
// }

export interface UserAuthInfo {
	errors: unknown;
	user: User;
	isAuthenticated: boolean;
}

export interface UserInstInfo {
	all: 0;
	run: 0;
	shutdown: 0;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
	errors = {};
	user = {} as User;
	subUser: IRespPageSubUser | any = {}
	// subUser = Array<ISubUser>() as [ISubUser]

	instInfo = {} as UserInstInfo;
	isAuthenticated = !!JwtService.getToken();
	menu_active_index = '1'
	respObj = ref({})
	open_id = ''
	userError = {}

	/**
	 * Get current user object
	 * @returns User
	 */
	get currentUser(): User {
		return this.user;
	}

	/**
	 * Verify user authentication
	 * @returns boolean
	 */
	get isUserAuthenticated(): boolean {
		return this.isAuthenticated;
	}

	/**
	 * Get authentification errors
	 * @returns array
	 */
	get getErrors() {
		return this.errors;
	}

	get getResp() {
		return this.respObj
	}

	get getUserError() {
		return this.userError
	}

	get getUserInstInfo() {
		return this.instInfo;
	}

	get getUserOpenId() {
		return this.open_id
	}

	get getSubUsers() {
		return this.subUser
	}

	@Mutation
	[UserMutations.SET_USER_OPEN_ID](open_id) {
		this.open_id = open_id
	}

	@Mutation
	[Mutations.SET_ERROR](error) {
		this.errors = { ...error };
	}

	@Mutation
	[Mutations.SET_AUTH](user) {
		this.isAuthenticated = true;
		this.user = user;
		this.errors = {};

		console.log("============set Token===========")
		console.log(user)
		// JwtService.saveToken(user.api_token);
		// const token = "123434"
		const token = user.content.token

		console.log(`set token....`)
		console.log(token)


		JwtService.saveToken(token);
		ApiService.setHeader();
	}

	@Mutation
	[Mutations.SET_USER](user) {
		this.user = user;
	}

	@Mutation
	[UserMutations.SET_SUB_USER](subUser) {
		this.subUser = subUser;
	}

	@Mutation
	[Mutations.SET_PASSWORD](password) {
		this.user.password = password;
	}

	get getMenuActiveIndex() {
		return this.menu_active_index
	}

	@Mutation
	[Mutations.SET_MENU_ACTIVE_INDEX](index) {
		this.menu_active_index = index;
	}

	@Mutation
	[Mutations.PURGE_AUTH]() {
		this.isAuthenticated = false;
		this.user = {} as User;
		this.errors = [];
		localStorage.removeItem("userInfo")
		JwtService.destroyToken();
	}

	@Mutation
	[UserMutations.SET_USER_SUCCESS](data) {
		this.respObj = data;
	}

	@Mutation
	[UserMutations.SET_USER_ERROR](data) {
		this.userError = data;
	}

	@Mutation
	[UserMutations.SET_USER_INST_CNT](data) {
		this.instInfo = data;
	}

	@Action
	[Actions.LOGIN](credentials) {

		return ApiService.post("api/auth/login", credentials)
			.then(({ data }) => {
				this.context.commit(Mutations.SET_AUTH, data);

				ApiService.setHeader();
				localStorage.setItem("userInfo", JSON.stringify(data.content))
			})
			.catch(({ response }) => {
				// this.context.commit(Mutations.SET_ERROR, response.data.errors);
				console.log("error-->>-")
				console.log(response)

				if (!response) {
					this.context.commit(Mutations.SET_ERROR, ["Server Connect Error"]);
				} else {
					try {
						this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
					} catch (err) {
						this.context.commit(Mutations.SET_ERROR, response.data.errors);
					}
				}

			});
	}

	@Action
	[Actions.LOGOUT]() {
		this.context.commit(Mutations.PURGE_AUTH);
	}

	@Action
	[Actions.REGISTER](credentials) {
		this.context.commit(Mutations.SET_ERROR, {});
		return ApiService.post("api/sms/create_user", credentials)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(Mutations.SET_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
				// this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.WX_BIND_REGISTER](credentials) {
		this.context.commit(Mutations.SET_ERROR, {});
		return ApiService.post("api/sms/create_user", credentials)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(Mutations.SET_ERROR, data.detail);
				} else {
					this.context.commit(Mutations.SET_AUTH, data);

					ApiService.setHeader();
					localStorage.setItem("userInfo", JSON.stringify(data.content))
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
				// this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.UPDATE_USER]() {
		// this.context.commit(Mutations.SET_ERROR, {});

		return ApiService.get("api/user/info")
			.then(({ data }) => {
				if (data.code != 0) {
					// this.context.commit(Mutations.SET_ERROR, data.detail);
				} else {
					let json = localStorage.getItem("userInfo")
					if (json) {
						let user = JSON.parse(json)
						user.balance = data.content.balance
						user.nick_name = data.content.nick_name
						user.wx_bind = data.content.wx_bind
						user.allow_sub = data.content.allow_sub
						this.user.balance = data.content.balance
						this.user.nick_name = data.content.nick_name
						this.user.wx_bind = data.content.wx_bind
						this.user.allow_sub = data.content.allow_sub


						localStorage.setItem("userInfo", JSON.stringify(user))
					} else {
						localStorage.setItem("userInfo", JSON.stringify(data.content))
					}

				}
			})
			.catch(({ response }) => {
				// this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
				// this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.FORGOT_PASSWORD](payload) {

		return ApiService.post("api/auth/password/update", payload)
			.then(({ data }) => {

				if (data.code != 0) {
					console.log("update resp...")
					console.log(data)
					console.log(data.detail)
					// this.context.commit(Mutations.SET_ERROR, {});
					this.context.commit(Mutations.SET_ERROR, data.detail);

				} else {
					this.context.commit(Mutations.PURGE_AUTH);
				}
			})
			.catch(({ response }) => {
				if (response.data.code) {
					this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
				} else {
					this.context.commit(Mutations.SET_ERROR, response.data.errors);
				}

			});
	}

	@Action
	[Actions.VERIFY_AUTH](payload) {
		if (JwtService.getToken()) {
			this.context.commit(Mutations.SET_ERROR, {});
			// ApiService.setHeader();
			// ApiService.post("verify_token", payload)
			//   .then(({ data }) => {
			//     this.context.commit(Mutations.SET_AUTH, data);
			//   })
			//   .catch(({ response }) => {
			//     this.context.commit(Mutations.SET_ERROR, response.data.errors);
			//     this.context.commit(Mutations.PURGE_AUTH);
			//   });
		} else {
			this.context.commit(Mutations.PURGE_AUTH);
		}
	}

	@Action
	[UserActions.GET_PHONE_CODE](payload) {

		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.post("/api/sms/send", payload)
			.then(({ data }) => {
				console.log(data)
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				console.log(response)
				this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})

	}

	@Action
	[UserActions.GET_WEIXIN_LOGIN_SHOWCODE](payload) {
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.get("api/wx/login")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				console.log(response)
				this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action({ rawError: true })
	[UserActions.CHECK_WEIXIN_CODE](payload) {
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.post("api/wx/query", payload)
			.then(({ data }) => {
				this.context.commit(UserMutations.SET_USER_SUCCESS, data);

				if (data.code == 0 && data.content.status == 1) {
					this.context.commit(Mutations.SET_AUTH, data)

					ApiService.setHeader();
					localStorage.setItem("userInfo", JSON.stringify(data.content))
				}
				// if (data.code != 0) {
				//   this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				// } else {
				//   this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				// }
			}).catch(({ response }) => {
				this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action({ rawError: true })
	[UserActions.GET_WEIXIN_BIND_SHOWCODE](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.get("api/auth/openid/bind")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.CHECK_WEIXIN_BIND_CODE](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.get("api/auth/openid/query")
			.then(({ data }) => {
				//  this.context.commit(UserMutations.SET_USER_SUCCESS, data);
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action({ rawError: true })
	[UserActions.FULFILL_PREPAY](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.post("api/fulfill/prepay", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.CHECK_WEIXIN_REEPAY_CODE](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.get(encodeUrlParam("api/fulfill/get", payload))
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.MANUAL_CHECK_WEIXIN_REEPAY_CODE](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");
		return ApiService.post("api/fulfill/get", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_SUCCESS, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.GET_USER_INST_CNT]() {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.get("api/device/inst/count")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_INST_CNT, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.CHECK_ALLOW_SUB_USER]() {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.get("api/user/sub/check")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_USER_INST_CNT, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.PAGE_SUB_USER](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/user/sub/page", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_SUB_USER, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}


	@Action
	[UserActions.CREATE_SUB_USER](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/user/sub/create", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.UPDATE_SUB_USER_PWD](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/user/sub/update/pwd", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.DELETE_SUB_USER](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/user/sub/del", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.ASSIGN_SUB_USER](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/user/sub/inst/allocate", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				}
			})
			.catch(({ response }) => {
				console.log(response)
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}

	@Action
	[UserActions.SUBACCOUNT_LOGIN](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.post("api/auth/sub/login", payload)
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(Mutations.SET_ERROR, data.detail);
				} else {
					this.context.commit(Mutations.SET_AUTH, data);

					ApiService.setHeader();
					localStorage.setItem("userInfo", JSON.stringify(data.content))

				}
			})
			.catch(({ response }) => {
				if (!response) {
					this.context.commit(Mutations.SET_ERROR, ["Server Connect Error"]);
				} else {
					try {
						this.context.commit(Mutations.SET_ERROR, [response.data.detail]);
					} catch (err) {
						this.context.commit(Mutations.SET_ERROR, response.data.errors);
					}
				}

			})
	}

	@Action
	[UserActions.SUBACCOUNT_INST_GET](payload) {
		ApiService.setHeader();
		this.context.commit(UserMutations.SET_USER_ERROR, "");

		return ApiService.get("api/user/sub/inst/get")
			.then(({ data }) => {
				if (data.code != 0) {
					this.context.commit(UserMutations.SET_USER_ERROR, data.detail);
				} else {
					this.context.commit(UserMutations.SET_SUB_USER, data.content);
				}
			})
			.catch(({ response }) => {
				response === undefined ?
					this.context.commit(UserMutations.SET_USER_ERROR, "error") :
					this.context.commit(UserMutations.SET_USER_ERROR, response.data.detail);
			})
	}





} 