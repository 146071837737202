import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layouts/main-layout/MainLayoutDashboard.vue"),
		// meta: {
		//   middleware: "auth",
		// },
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/Dashboard.vue"),
				meta: {
					pageTitle: "首页",
					breadcrumbs: ["Dashboards"],
					activeIndex: "1"
				},
			},
		]
	},

	{
		path: "/console",
		name: "console",
		component: () => import("@/layouts/main-layout/MainLayout.vue"),
		meta: {
			middleware: "console",

		},
		children: [
			{
				path: "/console/home",
				name: "account-home-personal",
				component: () => import("@/views/console/HomePersonal.vue"),
				meta: {
					pageTitle: "控制台",
					activeIndex: "999"
				},
			},
			{
				path: "/console/sub-home",
				name: "account-sub-home-personal",
				component: () => import("@/views/console/SubHomePersonal.vue"),
				meta: {
					pageTitle: "控制台",
					activeIndex: "999"
				},
			},
			{
				path: "/console/account",
				name: "account-overview",
				component: () => import("@/views/console/account/Account.vue"),
				meta: {
					pageTitle: "账户",
					activeIndex: "999"
				},
			},
			{
				path: "/console/subaccount",
				name: "account-subaccount",
				component: () => import("@/views/console/account/SubAccount.vue"),
				meta: {
					pageTitle: "子账户",
					activeIndex: "999"
				},
			},
			{
				path: "/console/order",
				name: "account-order",
				component: () => import("@/views/console/Order.vue"),
				meta: {
					pageTitle: "订阅",
					activeIndex: "999"
				},
			},
			{
				path: "/console/order/detail",
				name: "account-order-detail",
				component: () => import("@/views/console/OrderDetail.vue"),
				meta: {
					pageTitle: "订阅详情",
					activeIndex: "999"
				},
			},
			{
				path: "/console/instance",
				name: "account-inst-vm",
				component: () => import("@/views/console/instance/VmList.vue"),
				meta: {
					pageTitle: "实例",
					activeIndex: "999"
				},
			},

			{
				path: "/console/incomeExpend",
				name: "account-incomeExpend",
				component: () => import("@/views/console/InComExpend.vue"),
				meta: {
					pageTitle: "账单",
					activeIndex: "999"
				},
			},
			{
				path: "settings",
				name: "account-settings",
				component: () => import("@/views/crafted/account/Settings.vue"),
				meta: {
					pageTitle: "Settings",
					activeIndex: "999"
				},
			},
		],
	},


	{
		path: "/comm",
		name: "comm",
		component: () => import("@/layouts/main-layout/MainLayoutNoBanner.vue"),
		meta: {
			middleware: "comm",
		},
		children: [
			{
				path: "/recharge",
				name: "comm-recharge",
				component: () => import("@/views/comm/Recharge.vue"),
				meta: {
					pageTitle: "充值",
					activeIndex: "999"
				},
			},
			{
				path: "/market",
				name: "market-list",
				component: () => import("@/views/market/List.vue"),
				meta: {
					pageTitle: "算力市场",
					activeIndex: "2"
				},

			},
			{
				path: "/notice",
				name: "notice",
				component: () => import("@/views/doc/Notice.vue"),
				meta: {
					pageTitle: "通知",
					breadcrumbs: ["notice"],
					activeIndex: "001"
				},
			},
			{
				path: "/notice-list",
				name: "notice-list",
				component: () => import("@/views/doc/NoticeList.vue"),
				meta: {
					pageTitle: "通知",
					breadcrumbs: ["notice"],
					activeIndex: "001"
				},
			},
			{
				path: "/notice-pub",
				name: "notice-pub",
				component: () => import("@/views/doc/NoticeTestSub.vue"),
				meta: {
					pageTitle: "测试发布",
					breadcrumbs: ["notice"],
					activeIndex: "001"
				},
			},
		],
	},

	{
		path: "/help",
		name: "help",
		// component: () => import("@/layouts/main-layout/MainLayout.vue"),
		component: () => import("@/layouts/main-layout/MainLayoutNoBanner.vue"),
		meta: {
			middleware: "help",
		},
		children: [
			{
				path: "/downloads",
				name: "downloads",
				component: () => import("@/views/downloads/View.vue"),
				meta: {
					pageTitle: "软件下载",
					activeIndex: "4"
				},
			},
		]
	},
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layouts/main-layout/MainLayout.vue"),
		meta: {
			middleware: "auth",
		},
		children: [
			{
				path: "/console/account",
				name: "console-account",
				component: () => import("@/views/console/account/Account.vue"),
				meta: {
					pageTitle: "account",
				},
			},

			// {
			//   path: "/dashboard",
			//   name: "dashboard",
			//   component: () => import("@/views/Dashboard.vue"),
			//   meta: {
			//     pageTitle: "Dashboard",
			//     breadcrumbs: ["Dashboards"],
			//   }, 
			// },  
			{
				path: "/builder",
				name: "builder",
				component: () => import("@/views/LayoutBuilder.vue"),
				meta: {
					pageTitle: "Layout Builder",
					breadcrumbs: ["Layout"],
				},
			},
		],
	},
	{
		path: "/",
		component: () => import("@/layouts/AuthLayout.vue"),
		children: [
			{
				path: "/sign-in",
				name: "sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
				meta: {
					pageTitle: "用户登录",
				},
			},
			{
				path: "/wx-sign-in",
				name: "wx-sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/WecharSignln.vue"),
				meta: {
					pageTitle: "用户登录",
				},
			},
			{
				path: "/subSccount-sign-in",
				name: "subSccount-sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SubAccountSignIn.vue"),
				meta: {
					pageTitle: "子帐户登录",
				},
			},
			{
				path: "/BindingMobile",
				name: "BindingMobile",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/BindingMobile.vue"),
				meta: {
					pageTitle: "绑定手机号",
				},
			},
			{
				path: "/sign-up",
				name: "sign-up",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
				meta: {
					pageTitle: "用户注册",
				},
			},
			{
				path: "/password-reset",
				name: "password-reset",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
				meta: {
					pageTitle: "忘记密码",
				},
			},
		],
	},




	{
		path: "/multi-step-sign-up",
		name: "multi-step-sign-up",
		component: () =>
			import("@/views/crafted/authentication/MultiStepSignUp.vue"),
		meta: {
			pageTitle: "Multi-step Sign up",
		},
	},
	{
		path: "/",
		component: () => import("@/layouts/SystemLayout.vue"),
		children: [
			{
				// the 404 route, when none of the above matches
				path: "/404",
				name: "404",
				component: () => import("@/views/crafted/authentication/Error404.vue"),
				meta: {
					pageTitle: "Error 404",
				},
			},
			{
				path: "/500",
				name: "500",
				component: () => import("@/views/crafted/authentication/Error500.vue"),
				meta: {
					pageTitle: "Error 500",
				},
			},
		],
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	// history: createWebHashHistory(),
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	// current page view title
	document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

	// reset config to initial state
	store.commit(Mutations.RESET_LAYOUT_CONFIG);

	// verify auth token before each page change
	store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

	// before page access check if page requires authentication
	if (to.meta.middleware == "auth") {
		if (store.getters.isUserAuthenticated) {
			next();
		} else {
			next({ name: "sign-in" });
		}
	} else {
		next();
	}

	// Scroll page to top on every route change
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
});

export default router;