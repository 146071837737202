import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";

const router = useRouter();
// 统一处理错误返回
const ResponseErrorFun = (response) => {

    console.log("ResponseErrorFunResponseErrorFunResponseErrorFun")
    if (response.data.Code === 400020) {
        let timerInterval;
        Swal.fire({
            text: "登录失效，请重新登录",
            icon: "error",   // 有4个Icon  【success、error、warning、info、question】
            html: '登录失效，请重新登录<b></b>即将跳转至登录页面',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
                location.href="/sign-in"
                // router.push({ name: "sign-in" })
                console.log("to login page")
            }
            }).then((result) => {
                router.push({ name: "sign-in" })
                location.href="/sign-in"
                // console.log("to login page2222")
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
                // location.href="#/sign-in"
             
            }
    
        });
    
        
    } else {

        return response;
    }
}

// 拼接URL上的参数， 如 client/types?client_uuid=555646&client_types=2
const encodeUrlParam = (url: string, params: Array<object> | null):string => {
    let _url:string = "";

    if (!params)
        return url

    let _parString = ""
    for (const [key,value] of Object.entries(params)) {
        _parString += `&${key}=${value}`
    }

    if (_parString.startsWith("&"))
        _parString = _parString.slice(1)

    return `${url}?${_parString}`
}

export { ResponseErrorFun, encodeUrlParam }