enum MarketActions {
  // action types
	GET_PUBLIC_PAGES 	= "GET_PUBLIC_PAGES",
	GET_AREA_GROUP 		= "GET_AREA_GROUP",
	GET_GPU_SPEC_GROUP 	= "GET_GPU_SPEC_GROUP",

	PAGE_NOTICE = "pageNotices",
	GET_NOTICE = "getOneNotice",
}

enum MarketMutations {
  // mutation types
	SET_MARKET_ERROR 		= "SET_MARKET_ERROR",
	UPDATE_PUBLIC_PAGES 	= "UPDATE_PUBLIC_PAGES",
	UPDATE_AREA_GROUP 		= "UPDATE_AREA_GROUP",
	UPDATE_GPU_SPEC_GROUP 	= "UPDATE_GPU_SPEC_GROUP",

	SET_NOTICE = "setNotices",
}

export { MarketActions, MarketMutations };
