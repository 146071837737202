enum UserActions {
  // action types
  GET_PHONE_CODE = "getPhoneCode",

  // 获取登录二维码
  GET_WEIXIN_LOGIN_SHOWCODE = "WX_SHOWQRCODE",
  CHECK_WEIXIN_CODE = "WX_CHECKWQRCODE",

  // 用户绑定二维码
  GET_WEIXIN_BIND_SHOWCODE = "WX_SHOW_BIND_QRCODE",
  CHECK_WEIXIN_BIND_CODE = "WX_CHECK_BIND_WQRCODE",

  // 充值金额
  FULFILL_PREPAY = "FULFILL_PREPAY",
  CHECK_WEIXIN_REEPAY_CODE = "CHECK_WEIXIN_REEPAY_CODE",
  MANUAL_CHECK_WEIXIN_REEPAY_CODE = "MANUAL_CHECK_WEIXIN_REEPAY_CODE",

  GET_USER_INST_CNT = "GET_USER_INST_CNT",

  // subUser
  CHECK_ALLOW_SUB_USER = "CHECK_ALLOW_SUB_USER",
  CREATE_SUB_USER = "CREATE_SUB_USER",
  UPDATE_SUB_USER_PWD = "UPDATE_SUB_USER_PWD",
  DELETE_SUB_USER = "DELETE_SUB_USER",
  ASSIGN_SUB_USER = "ASSIGN_SUB_USER",
  QUERY_SUB_USERANDINST = "QUERY_SUB_USERANDINST",
  PAGE_SUB_USER = "PAGE_SUB_USER",
  SUBACCOUNT_LOGIN = "SUBACCOUNT_LOGIN",
  SUBACCOUNT_INST_GET = "SUBACCOUNT_INST_GET"


}

enum UserMutations {
  // mutation types
  SET_USER_ERROR = "setUserError",

  SET_USER_SUCCESS = "",

  SET_USER_INST_CNT = "SET_USER_INST_CNT",

  SET_USER_OPEN_ID = "SET_USER_OPEN_ID",

  SET_SUB_USER = "SET_SUB_USER"
}

export { UserActions, UserMutations };
